import './App.css';
import React, { useState, useEffect } from "react";
import { RedocStandalone } from "redoc";

function App() {
  const optionsArray = [
    'https://jmss-only.azurewebsites.net/swagger/docs/v1',
    'https://jmss-only2.azurewebsites.net/swagger/docs/v1',
    
    'https://jmss-only-insprice.azurewebsites.net/swagger/v1/swagger.json']; // Public swagger/yaml URL definitions 

  const handleInputChange = (event) => {
    setSwaggerDefinition(null);
    handleLoadDefinition(event.target.value);
  };
  const [swaggerDefinition, setSwaggerDefinition] = useState(null);
  const [disableSelect, setDisableSelect] = useState(true);
  const [error, setError] = useState(null);

  async function handleLoadDefinition(url) {
    if (!isValidUrl(url)) {
      setError("Invalid URL. Please enter a valid URL.");
      return;
    }
    setDisableSelect(true);
    try {
      const response = await fetch(url);
      const result = await response.json();
      setSwaggerDefinition(result);
      setError(null);
    } catch (error) {
      setError(`Error fetching Swagger definition: ${error.message}`);
      console.error(error.message);
    } finally {
      setDisableSelect(false);
    }
  };

  useEffect(() => {
    // Load the first URL from optionsArray when the component mounts
    handleLoadDefinition(optionsArray[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isValidUrl = (url) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <div >
      <img src="jm-logo.png" alt="Logo" className="logo-image" />
      <div className="app-container input-container">
        <div className="select-container">
          <label className="select-label">
            Select definition:
            <select disabled={disableSelect === true} className="select-dropdown" onChange={handleInputChange}>
              {optionsArray.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </label>
          <div className="custom-arrow"></div>
        </div>
      </div>

      <div className="swagger-container">
        {error ? (
          <p className="error-message">Error: {error}</p>
        ) : swaggerDefinition ? (
          <RedocStandalone
            spec={swaggerDefinition}
            options={{
              nativeScrollbars: true,
              theme: {
                rightPanel: {
                  width: '30%'
                },
                colors: { primary: { main: "blue" } },
                typography: {
                  fontSize: "14px",
                  code: {
                    fontSize: "13px",
                  },
                },
              },
              lazyRendering: true,
              hideDownloadButton: true,
            }}
          />
        ) : (
          <p className="loading-message">Loading Swagger definition...</p>
        )}
      </div>
    </div>
  );
}

export default App;
